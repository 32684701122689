import { gsap, Expo } from 'gsap'
import { initializeApp } from 'firebase/app'
import { doc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAtqNdPtdwlfCAFqLdb-X5G-4qNdvOOPMA',
  authDomain: 'fbcounter-d4454.firebaseapp.com',
  projectId: 'fbcounter-d4454',
  storageBucket: 'fbcounter-d4454.appspot.com',
  messagingSenderId: '349946635431',
  appId: '1:349946635431:web:1451ba18e4c4973dd36782'
}

initializeApp(firebaseConfig)

function write(text: string, delayMs: number): Promise<void> {
  return new Promise((resolve, reject) => {
    const termCommandElement = document.querySelector<HTMLSpanElement>('#term_command')
    const termCursorElement = document.querySelector<HTMLDivElement>('#term_cursor')

    let i = 0;
    if (termCommandElement) {
      termCursorElement?.classList.remove('anim-blink')

      const print = () => {
        i++
        termCommandElement.textContent = text.slice(0, i)
        setTimeout(print, delayMs)

        if (i === text.length) {
          termCursorElement?.classList.add('anim-blink')
          resolve()
        }
      }

      print()
    } else {
      reject()
    }
  })
}

function wait(ms: number): Promise<void> {
  return new Promise((resolve, _) => setTimeout(resolve, ms))
}

function drawCanvas(): Promise<void> {
  return new Promise((resolve, _) => {
    const checker = document.querySelector<HTMLCanvasElement>('#checker')
    if (!checker) return resolve()

    checker.width = window.innerWidth
    checker.height = window.innerHeight
    checker.parentElement?.querySelectorAll('div').forEach(div => div.classList.remove('hidden'))

    const ctx = checker.getContext('2d')!
    ctx.fillStyle = '#ffffff'
    const [nx, ny] = [Math.ceil(checker.width / 20), Math.ceil(checker.height / 20)]
    let done = 0

    for (let y = 0; y < ny; y++) {
      for (let x = 0; x < nx; x++) {
        setTimeout(() => {
          done++
          if (done === nx * ny) resolve()

          ctx.fillRect(x * 20, y * 20, 20, 20)
        }, Math.random() * 700)
      }
    }
  })
}

function updateCount(count: number): Promise<void> {
  if (!Number.isInteger(count) || count < 0) throw new Error('count must be a non-negative integer')

  return new Promise((resolve, _) => {
    const counter = document.querySelector<HTMLDivElement>('#counter')
    const digitsHolder = counter?.children.item(0) as HTMLDivElement | null

    const tl = gsap.timeline({
      onComplete: resolve
    })

    if (digitsHolder) {
      const beforeText = digitsHolder.dataset.count ?? ''
      const afterText = count.toString()

      if (afterText.length >= beforeText.length) {
        const fragment = document.createDocumentFragment()

        const diff = afterText.length - beforeText.length

        const divs = Array.from(afterText.slice(0, diff))
          .map(char => {
            const div = document.createElement('div')
            div.classList.add('inline-block')
            div.textContent = char

            fragment.appendChild(div)

            return div
          })

        digitsHolder.insertBefore(fragment, digitsHolder.children[0])

        for (let i = afterText.length; i >= diff; i--) {
          const div = digitsHolder.children[i]
          if (!div || div.textContent === afterText[i]) continue

          div.textContent = afterText[i]
          tl.from(div, { translateY: 40, ease: Expo.easeOut }, '<15%')
        }

        divs.reverse().forEach(div => {
          tl.from(div, { opacity: 0, translateY: 40, ease: Expo.easeOut }, '<15%')
        })
      } else {
        const diff = beforeText.length - afterText.length

        Array.from(digitsHolder.children).slice(0, diff).forEach(div => {
          tl
            .to(div, {
              opacity: 0,
              translateY: 40,
              ease: Expo.easeIn,
              onComplete: () => div.remove()
            }, '<15%')
        })

        for (let i = 0; i < afterText.length; i++) {
          const div = digitsHolder.children[diff + i]
          if (!div || div.textContent === afterText[i]) continue
          tl.from(div, {
            onStart: () => { div.textContent = afterText[i] },
            translateY: 40,
            immediateRender: false
          }, '<15%')
        }
      }

      digitsHolder.dataset.count = afterText
    }
  })
}

async function run() {
  await wait(300)
  await write('read_count()', 100)
  const termOutput = document.querySelector<HTMLHeadingElement>('#term_output')
  termOutput?.classList.remove('hidden')

  let updated = false
  let animationTask: Promise<void>;

  const count = await new Promise<number>((resolve) => {
    const db = getFirestore()
    onSnapshot(doc(db, 'counters', 'total'), (snap) => {
      const c: number = snap.data()?.count ?? 0
      if (!updated) {
        resolve(c)
      } else {
        if (animationTask) {
          animationTask = animationTask.then(() => updateCount(c))
        } else {
          animationTask = updateCount(c)
        }
      }
      updated = true
    })
  })

  await drawCanvas()
  await wait(100)

  const counter = document.querySelector<HTMLDivElement>('#counter')
  if (counter) {
    counter.classList.remove('hidden')
    gsap.ticker.fps(24)

    const tl = gsap.timeline({
      onComplete: () => {
        const footer = document.querySelector<HTMLDivElement>('#footer')
        footer?.classList.remove('opacity-0')
      }
    })

    tl.fromTo(
      counter,
      {
        translateY: 200,
        opacity: 0
      },
      {
        translateY: 0,
        opacity: 1,
        ease: Expo.easeOut,
        duration: 2
      }
    )

    tl.to(
      counter.children.item(1),
      {
        translateX: 8,
        translateY: 8,
        duration: 0.3
      },
      '<25%'
    )

    await updateCount(count)
  }
}

run()
